<script setup lang="ts">
import { MMRFQEngagementOptions, MMRFQExternalCapitalOptions, MMRFQMotivationsOptions, MMRFQStableCoinBudgetToAllocateOptions, MMRFQTokenSupplyPercentageToAllocateOptions } from '@forgd/contract'
import { countries } from '@forgd/shared/data/countries'

const props = defineProps<{
  data: {
    project: {
      id: string
      name: string
      tokenListed: boolean
      baseLayer: string[]
      image: string
      ticker: string
      coingeckoId: string
    }
    rfq: {
      marketMakersQty: number
      engagementOptions: string[]
      tokenSupplyPercentageToAllocate: string
      stableCoinBudgetToAllocate: string
      motivations: string[]
      exchangesCEX: string[]
      exchangesDEX: string[]
      externalCapital: string
      investors: string[]
      country: string
      hasLegalOpinionOnUtilityStatus: boolean
    }
    tokenMarketDetails?: {
      maxTokenSupply: number
      circulatingSupply: number
      marketCap: number
      price: number
      fdv: number
    }
    tokenDesignerDetails?: {
      maxTokenSupply: number
      fdvAtTGE: number
      tokenPriceAtTGE: number
      percentageUnlockedAtTGE: number
      circulatingSupply: number
      marketCapAtTGE: number
    }
  }
  loading?: boolean
}>()
const { publicPageDomain } = useRuntimeConfig().public

const btnNavigateTo = computed(() => {
  if (Object.keys(props.data.project).length === 0) {
    return {
      url: '',
      title: '',
    }
  }

  const project = props.data.project!
  return {
    url: project.coingeckoId ? `https://coingecko.com/en/coins/${project.coingeckoId}` : `https://${publicPageDomain}/share/${project.ticker.toLowerCase()}/${project.id}/public`,
    title: project.coingeckoId ? 'CoinGecko ID' : 'Token Public Page',
  }
})

const tokenomicsDataSource = computed(() => {
  if (props.data.tokenDesignerDetails) {
    return {
      maxTokenSupply: props.data.tokenDesignerDetails.maxTokenSupply,
      circulatingSupply: props.data.tokenDesignerDetails.circulatingSupply,
      price: props.data.tokenDesignerDetails.tokenPriceAtTGE,
      marketCap: props.data.tokenDesignerDetails.marketCapAtTGE,
      fdv: props.data.tokenDesignerDetails.fdvAtTGE,
    }
  }

  if (props.data.tokenMarketDetails) {
    return {
      maxTokenSupply: props.data.tokenMarketDetails.maxTokenSupply,
      circulatingSupply: props.data.tokenMarketDetails.circulatingSupply,
      price: props.data.tokenMarketDetails.price,
      marketCap: props.data.tokenMarketDetails.marketCap,
      fdv: props.data.tokenMarketDetails.fdv,
    }
  }

  return null
})

const tokenomicsData = computed(() => {
  const data = tokenomicsDataSource.value

  if (!data) {
    return []
  }

  return [
    {
      label: props.data.project.coingeckoId ? 'Circulating Token Supply' : 'Circulating Token Supply at TGE',
      value: formatValue(data.circulatingSupply, 'number'),
    },
    {
      label: 'Total Token Supply',
      value: formatValue(data.maxTokenSupply, 'number'),
    },
    {
      label: props.data.project.coingeckoId ? 'Token Price' : 'Est. Token Price at TGE',
      value: formatValue(data.price, 'currency'),
    },
    {
      label: 'Market Capitalization ("MC")',
      value: formatValue(data.marketCap, 'currency'),
    },
    {
      label: 'Fully Diluted Valuation ("FDV")',
      value: formatValue(data.fdv, 'currency'),
    },
  ]
})

const rfqPreferences = computed(() => {
  if (Object.keys(props.data.rfq).length === 0) {
    return [[], []]
  }
  const data = props.data?.rfq

  const section1 = [
    {
      label: 'Desired Number of Market Makers',
      value: formatValue(data.marketMakersQty, 'number'),
    },
    {
      label: 'Preferred Business Model',
      value: MMRFQEngagementOptions.filter(option => data.engagementOptions.includes(option.value)).map(o => o.label).join(', '),
    },
    {
      label: 'Est. Engagements Token Allocation',
      value: MMRFQTokenSupplyPercentageToAllocateOptions.find(item => item.value === data.tokenSupplyPercentageToAllocate)?.value,
    },
    {
      label: 'Est. Engagement Stablecoin Budget',
      value: MMRFQStableCoinBudgetToAllocateOptions.find(item => item.value === data.stableCoinBudgetToAllocate)?.value,
    },
    {
      label: 'Priorities & Motivations',
      value: MMRFQMotivationsOptions.filter(item => data.motivations.includes(item.value)).map(o => o.label).join(', '),
    },
  ]

  const section2 = [
    {
      label: 'Desired CEXs for TGE',
      value: data.exchangesCEX.join(', '),
    },
    ...(props.data.project.tokenListed
      ? []
      : [{
          label: 'Desired DEXs for TGE',
          value: data.exchangesDEX.join(', '),
        }]
    ),
    {
      label: 'External Capital Raised to Date',
      value: MMRFQExternalCapitalOptions.find(option => option.value === data.externalCapital)?.label,
    },
    {
      label: 'Notable Investors',
      value: data.investors.join(', '),
    },
    {
      label: 'Jurisdiction of Token Issuing Entity',
      value: data.country === 'i_dont_know' ? `I do not know` : countries.find(c => c.value === data.country)?.label,
    },
    {
      label: 'Has Legal Opinion?',
      value: data.hasLegalOpinionOnUtilityStatus ? 'Yes' : 'No',
    },
  ]
  return [section1, section2]
})
</script>

<template>
  <UiSlideover
    id="quote-preferences"
    title="All RFQ Preferences"
    size="sm"
    description="The following list shows all RFQ preferences the project described on the application."
    :overlay="false"
  >
    <UiLoading size="md" :loading="loading" :class="loading ? 'm-auto' : undefined">
      <div v-if="props.data !== null" class="flex flex-col gap-5">
        <div class="flex items-center gap-2">
          <ProjectImage :src="props.data.project.image" class="h-4 w-4" />
          <div class="font-semibold text-primary-900">
            {{ props.data.project.ticker }}
          </div>
          <div class="text-xs text-gray-600">
            {{ props.data.project.name }}
          </div>
        </div>
        <div class="flex items-center justify-between flex-wrap">
          <div class="flex gap-1">
            <UiBadge size="md" color="gray">
              {{ props.data.project.tokenListed ? 'Listed' : 'Pre-TGE' }}
            </UiBadge>
            <UiBaseLayer :layers="props.data.project.baseLayer" class="h-8" />
          </div>
          <UiButton
            variant="outline"
            icon="i-heroicons-arrow-up-right"
            :to="btnNavigateTo.url"
            size="sm"
            class="h-8"
            trailing
          >
            <div class="flex items-center gap-1">
              <img v-if="props.data.project.coingeckoId" src="../../../public/coingecko.svg" class="w-3 h-3 rounded-full">
              <UiIcon v-else name="i-heroicons-globe-alt" size="sm" />

              <div class="text-xs font-semibold">
                {{ btnNavigateTo.title }}
              </div>
            </div>
          </UiButton>
        </div>
        <UiCard title="Tokenomics Details" is-sticky>
          <div class="flex flex-col gap-2">
            <div v-for="item in tokenomicsData" :key="item.label" class="grid grid-cols-2 gap-2 items-center">
              <span class="text-sm text-primary-900">{{ item.label }}</span>
              <div class="bg-neutral-200 p-2 rounded-lg text-sm text-primary-900">
                {{ item.value }}
              </div>
            </div>
          </div>
        </UiCard>
        <UiCard title="RFQ Preferences" is-sticky>
          <div class="flex flex-col gap-2">
            <div v-for="item in rfqPreferences[0]" :key="item.label" class="grid grid-cols-2 gap-2 items-center">
              <span class="text-sm text-primary-900">{{ item.label }}</span>
              <div class="bg-neutral-200 p-2 rounded-lg text-sm text-primary-900">
                {{ item.value || 'N/A' }}
              </div>
            </div>
          </div>
        </UiCard>
        <UiCard title="Additional Details" is-sticky>
          <div class="flex flex-col gap-2">
            <div v-for="item in rfqPreferences[1]" :key="item.label" class="grid grid-cols-2 gap-2 items-center">
              <span class="text-sm text-primary-900">{{ item.label }}</span>
              <div class="bg-neutral-200 p-2 rounded-lg text-sm text-primary-900">
                {{ item.value || 'N/A' }}
              </div>
            </div>
          </div>
        </UiCard>
      </div>
    </UiLoading>
  </UiSlideover>
</template>
